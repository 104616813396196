import React, { useEffect, useState } from 'react';
import { changeSettingInfo, getSettingInfo } from '../../http/admin';
import { IoMdArrowBack } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import styles from './Settings.module.css';
import Sidebar from '../../components/Sidebar/Sidebar';
import Loader from '../../components/Loader/Loader';
import { enqueueSnackbar } from 'notistack';
import Refferal from '../../components/Refferal/Refferal';

function Settings() {
    const [info, setInfo] = useState(null);
    const [addPayment, setAddPayment] = useState('');
    const [save, setSave] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getInfo = async () => {
            setLoading(true);
            const data = await getSettingInfo();
            setInfo(data);
            setLoading(false);
        }

        getInfo();
    }, [])

    const handleSave = async (status) => {

        setSave(status)

        if (!status) {
            await changeSettingInfo(info);
            enqueueSnackbar('Успешно сохранено!', { variant: 'success' });
        }

    }

    const handleChange = (text, name) => {
        setInfo({ ...info, [name]: text });
    }

    const handleAddPaymentMethod = () => {
        if (save && addPayment !== '') {
            setInfo({ ...info, methods: [{ methodName: addPayment }, ...info.methods] });
            setAddPayment('');
        }
    }

    const handleDeletePaymentMethod = (methodNameToDelete) => {
        setInfo({
            ...info,
            methods: info.methods.filter(method => method.methodName !== methodNameToDelete)
        });
    };

    console.log(info);

    return (
        <>
            <Sidebar />
            <section className={styles.section}>
                <h2 className={styles.title}>Настройки</h2>

                {loading ? (
                    <div className={styles.loader}><Loader /></div>
                ) : (
                    <div className={styles.settings__wrapper}>
                        <div className={styles.settings}>
                            <div className={styles.container__wrapper}>
                                <div className={styles.container}>
                                    <div className={styles.filed__wrapper} >
                                        <div className={styles.field} >
                                            <label className={styles.label}>Способы оплаты:</label>
                                            <ul className={styles.ul}>
                                                {info.methods.map(method => {
                                                    return <li className={styles.li} key={method.methodName}>
                                                        {method.methodName} <IoMdClose className={styles.close}
                                                            onClick={() => save && handleDeletePaymentMethod(method.methodName)} /></li>
                                                })}
                                            </ul>

                                        </div>

                                        <div className={styles.enter__wrapper}>
                                            <div className={styles.field} >
                                                <label className={styles.label}>Добавить:</label>
                                                <input value={addPayment} onChange={(e) => setAddPayment(e.target.value)}
                                                    className={styles.input} type="text" disabled={!save} placeholder='Новый способ оплаты' />
                                            </div>
                                            <IoMdArrowBack className={styles.enter}
                                                onClick={() => handleAddPaymentMethod()} />
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.comission__wrapper} >

                                    <div className={styles.comission__field} >
                                        <label className={styles.label}>Комиссия:</label>
                                        <p className={styles.comission__value} >
                                            <input className={styles.comission__input} value={info.commision_min}
                                                onChange={(e) => handleChange(e.target.value, 'commision_min')} type="text" disabled={!save} />$<p>
                                                +</p><input className={styles.comission__input} value={info.commision_percent}
                                                    onChange={(e) => handleChange(e.target.value, 'commision_percent')} type="text" disabled={!save} />%</p>
                                    </div>

                                    <div className={styles.comission__field} >
                                        <label className={styles.label}>Макс. (без верификации):</label>
                                        <input className={styles.comission__input} value={info.max}
                                            onChange={(e) => handleChange(e.target.value, 'max')} type="text" disabled={!save} />
                                    </div>

                                    <div className={styles.comission__field} >
                                        <label className={styles.label}>POKER_OK:</label>
                                        <input className={styles.login__input}
                                            value={info.login_poker_ok}
                                            onChange={(e) => handleChange(e.target.value, 'login_poker_ok')}
                                            placeholder='Новый логин' type="text" disabled={!save} />
                                    </div>

                                    <div className={styles.comission__field} >
                                        <label className={styles.label}>ACR:</label>
                                        <input className={styles.login__input}
                                            value={info.login_poker_king}
                                            onChange={(e) => handleChange(e.target.value, 'login_poker_king')}
                                            placeholder='Новый логин' type="text" disabled={!save} />
                                    </div>

                                    <div className={styles.comission__field} >
                                        <label className={styles.label}>RED_STAR:</label>
                                        <input className={styles.login__input}
                                            value={info.login_red_star}
                                            onChange={(e) => handleChange(e.target.value, 'login_red_star')}
                                            placeholder='Новый логин' type="text" disabled={!save} />
                                    </div>

                                </div>

                                {save ?
                                    <button className={styles.button__active} onClick={() => handleSave(false)}>Сохранить</button> :
                                    <button className={styles.button} onClick={() => handleSave(true)}>Изменить</button>}
                            </div>

                            <Refferal refferals={info.referals} loading={loading} setLoading={setLoading} />
                        </div>
                    </div>
                )}
            </section>
        </>
    );
}

export default Settings;

